$(function(){

  /*
   * HEY. domains hash exists in :
   * site/js/lang_toggle.js
   * site/js/lang_redirect.js
   *
   * THEY MUST STAY THE SAME. chnage one, change the other.
   */
    var domains = {
      // France
      'fr_FR' : '',
      'en_FR' : '/en-e-fr'
    };

  function getParameterByName(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0].toLowerCase() === parameterName.toLowerCase()) result = decodeURIComponent(tmp[1]);
    }
    return result;
  }

  var langParam = getParameterByName('locale');
  if (langParam) {
    switchLanguageDrupal(langParam);
  }

  function switchLanguageDrupal(lang) {
    // We're on the wrong domain
    if (lang !== generic.cookie('LOCALE')) {
      var pathSplit = window.location.pathname.split('/');
      var path = pathSplit[1] || '';

      document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      generic.cookie('LOCALE', lang, {path : '/'});

      // search for existing domain[lang] in path
      var found = _.some(domains, function(domain) {
        if (domain !== '') {
          return domain.indexOf(path) !== -1;
        }
      });

      // strip old domain from path
      if (found) {
        pathSplit.splice(1, 1);
      }

      // reconstruct path
      var fullPath = pathSplit.join('/');

      // empty domains[lang] in certain cases
      if (fullPath.indexOf('.tmpl') > 0 || !domains[lang]) {
        domains[lang] = '';
      }

      var url = window.location.protocol + '//' + window.location.host + domains[lang] + fullPath + window.location.hash;
      window.location.href = url;
    }
  }

  $('.switch-lang-link-ch, .switch-lang-link').on('click', function(event) {
      event.preventDefault();
      return switchLanguageDrupal( $(this).attr('data-mp-lang') );
  });
});
